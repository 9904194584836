import { request, requestNoApi } from "@/utils/request";
// let res = await getBaseList('/system/dicSet/getDicItemByCode',{code:dictCode});
// return res;
const repairsApi = {
  commitRepairs: "/room/WxPublicAccounts/insertRepairBill",
  repairList: "/room/zmRepairApply/selectByScheduleStatus",
  repairDetail: "/room/zmRepairApply/getDetailByUuid",
  repairHistory: "/room/zmRepairApplyHis/selectByRepairApplyId",
  cancelRepair: "/room//zmRepairApply/cancelRepair",
  complaint: "/room/zmSuggestion/save",
};

// export function login(params) {
//   return request({
//     url: loginApi.login,
//     method: "POST",
//     data: params,
//   });
// }
// export function wxLogin(params) {
//   return request({
//     url: loginApi.wxLogin,
//     method: "GET",
//     params,
//   });
// }
export function commitRepairs(params) {
  return request({
    url: repairsApi.commitRepairs,
    method: "POST",
    data: params,
  });
}

export function getRepairList(params) {
  return request({
    url: repairsApi.repairList,
    method: "POST",
    data: params,
  });
}

export function repairDetail(params) {
  return request({
    url: repairsApi.repairDetail,
    method: "GET",
    params: params,
  });
}

export function repairHistory(params) {
  return request({
    url: repairsApi.repairHistory,
    method: "GET",
    params: params,
  });
}

export function cancelRepair(params) {
  return request({
    url: repairsApi.cancelRepair,
    method: "GET",
    params: params,
  });
}

export function complaint(params) {
  return request({
    url: repairsApi.complaint,
    method: "POST",
    data: params,
  });
}

//报修评价
export function evaluateAdd(params) {
  return request({
    url: '/room/zmRepairApply/evaluateApply',
    method: "POST",
    data: params,
  });
}

//通知公告
export function zmNoticeList(params) {
  return request({
    url: '/room/zmNotice/getPageSet',
    method: "POST",
    data: params,
  });
}

//通知公告详情
export function getDetailByUuid(params) {
  return request({
    url: '/room/zmNotice/getDetailByUuid',
    method: "GET",
    params:params,
  });
}


//获取楼栋
export function getBuildingByTenantId(params) {
  return request({
    url: '/room/zmBuilding/getBuildingByTenantId',
    method: "GET",
    data: params,
  });
}