<template>
	<!-- 报事/报修 -->
	<div class="page_main">
		<!-- <van-nav-bar title="投诉/建议"
                 left-text="返回"
                 left-arrow
                 @click-left="onClickLeft" /> -->
		<van-form @submit="onSubmit">
			<div class="title_area fs_14">评价</div>
			<van-field name="evaluateScore" label="评分">
				<template #input>
					<van-rate v-model="formObj.evaluateScore" />
				</template>
			</van-field>

			<van-field
				v-model="formObj.evaluateContent"
				name="description"
				label="评价内容"
				rows="2"
				autosize
				type="textarea"
				maxlength="254"
				show-word-limit
				placeholder="请输入问题描述"
			/>

			<!-- <div class=" mt_8"></div> -->

			<van-field name="uploader" label="评价图片">
				<template #input>
					<van-uploader :max-count="3" :after-read="afterRead" :before-delete="beforeDelete" v-model="formObj.photosAry" />
				</template>
			</van-field>

			<div style="margin: 16px;"><van-button block :disabled="isDisabled" :loading="isDisabled" type="info" color="#21AA61" native-type="submit">提交</van-button></div>
		</van-form>
	</div>
</template>
<script>
import { report } from '@/api/upload.js';
import { evaluateAdd } from '@/api/repairs.js';
import { Toast } from 'vant';
export default {
	props: {},
	components: {},
	data() {
		return {
			// form表单所有数据
			formObj: {
				uuid: '',
				evaluateContent: '', // 评价内容
				evaluatePicture: '', // 评价图片
				evaluateScore: 5 //评价分数
			},
			isDisabled: false,
			photoss: []
		};
	},
	created() {
		this.formObj.uuid = this.$router.currentRoute.query.uuid;
	},
	mounted() {},
	methods: {
		// 返回上一页
		onClickLeft() {
			history.back();
		},

		// 上传图片
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			// 此时可以自行将文件上传至服务器
			// console.log(file.file);
			report({ file: file.file, action: 'repair_apply' }).then(res => {
				// console.log(res)
				const data = res.data;
				console.log(data.statusCode);
				if (data.statusCode == 200) {
					this.photoss.push({
						url: data.data.fileName
					});
					console.log('123');
					file.status = 'done';
				} else {
					file.status = 'failed';
					file.message = '上传失败';
				}
			});
		},
		// 删除照片
		beforeDelete(a, b, c) {
			console.log(a, b, c);
			if (a.status == 'done') {
				this.photoss.splice(b.index, 1);
				return true;
			} else {
				return true;
			}
		},
		// 表单提交
		onSubmit() {
			console.log(this.formObj);
			const ary = [];
			this.photoss.forEach(item => {
				ary.push(item.url);
			});
			this.formObj.evaluatePicture = ary.join(',');
			this.isDisabled = true;
			evaluateAdd(this.formObj)
				.then(res => {
					this.isDisabled = false;
					if (res.code == 200) {
						Toast.success('评价成功');
						setTimeout(() => {
							this.$router.replace({ path: '/serve/serve-list', query: { active: 0 } });
						}, 1000);
					} else {
						Toast.fail(res.message);
					}
				})
				.finally(() => {
					this.isDisabled = false;
				});
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .van-field__label {
	color: #333333 !important;
	font-size: 16px;
}
.page_main {
	background-color: #f7f8fa;
	height: 100%;
	min-height: 100vh;
}
.title_area {
	height: 34px;
	line-height: 34px;
	background-color: #f7f8fa;
	color: #999999;
	padding: 0 14px;
}
</style>
