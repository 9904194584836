import { requestForm } from "@/utils/request";
// let res = await getBaseList('/system/dicSet/getDicItemByCode',{code:dictCode});
// return res;
const uploadApi = {
  baoxiu: "/document/oss/multiFileUpload",
};

// export function login(params) {
//   return request({
//     url: uploadApi.login,
//     method: "POST",
//     data: params,
//   });
// }
// export function wxLogin(params) {
//   return request({
//     url: uploadApi.wxLogin,
//     method: "GET",
//     params,
//   });
// }

export function report(params) {
  var data = new FormData();
  data.append("file", params.file);
  return requestForm({
    url: uploadApi.baoxiu + "?keyPrefix=" + params.action,
    method: "POST",
    data: data,
  });
}
